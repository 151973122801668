/*

src/components/RightSidebar/RightSidebar.js

This component is responsible for rendering the right sidebar of the application. 
The right sidebar contains a textarea for the user to input a prompt for the active slide. 
The user can then submit the prompt by clicking the "Submit Slide Prompt" button.

*/

import React, { forwardRef } from 'react';
import './RightSidebar.css';

const RightSidebar = forwardRef(
  ({ activeSlideIndex, slidePrompt, setSlidePrompt, handleSlidePromptSubmit }, ref) => {
    const handleSlidePromptChange = (event) => {
      const newSlidePrompts = [...slidePrompt];
      newSlidePrompts[activeSlideIndex] = event.target.value;
      setSlidePrompt(newSlidePrompts);
    };

    return (
      <div className="right-sidebar" ref={ref}>
        <textarea
          className="textarea-prompt"
          placeholder={`What do you want to change for Slide ${activeSlideIndex + 1}?`}
          value={slidePrompt[activeSlideIndex] || ''}
          onChange={handleSlidePromptChange}
        />
        <button onClick={() => handleSlidePromptSubmit(activeSlideIndex)}>Submit Slide Prompt</button>
      </div>
    );
  }
);

export default RightSidebar;