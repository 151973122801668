/* 
src/components/SlideComponents/SlideBulletsEditor.js

This component is responsible for rendering the SlideBulletsEditor component.
*/


import React from 'react';
import { Editor, EditorState } from 'draft-js';
import './SlideBulletsEditor.css';

const SlideBulletsEditor = ({ editorState, onChange }) => {
  const content = editorState.getCurrentContent().getPlainText();
  const bulletPoints = content.split('\n').filter(point => point.trim() !== '');

  return (
    <div className="slide-bullets-editor">
      <ul>
        {bulletPoints.map((bullet, index) => (
          <li key={index}>{bullet}</li>
        ))}
      </ul>
      <Editor editorState={editorState} onChange={onChange} placeholder="Enter bullet points" />
    </div>
  );
};

export default SlideBulletsEditor;
