/* 
src/components/SlideComponents/SlideSubtitleEditor.js

This component is responsible for rendering the SlideSubtitleEditor component.
*/

import React from 'react';
import { Editor } from 'draft-js';
import './SlideSubtitleEditor.css';

const SlideSubtitleEditor = ({ editorState, onChange }) => {
  return (
    <div className="slide-subtitle-editor">
      <h2>
        <Editor editorState={editorState} onChange={onChange} placeholder="Enter slide subtitle" />
      </h2>
    </div>
  );
};

export default SlideSubtitleEditor;
