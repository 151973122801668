// Context to store the slides so that they are accessible across different components. 

import React, { createContext, useState } from 'react'; // Import createContext and useState from React.

export const SlidesContext = createContext(); // Create a new context called SlidesContext.

export const SlidesProvider = ({ children }) => { // Create a new component called SlidesProvider.
  const [slides, setSlides] = useState([]); // Initialize the slides state with an empty array.

  return (
    <SlidesContext.Provider value={{ slides, setSlides }}>
      {children}
    </SlidesContext.Provider>
  );
};
