/*

This snippet is the API utility functions for the slide editor. 
It provides functions to get slides, regenerate a slide, regenerate all slides, save slides, and export slides.

*/

import axios from 'axios';

export const getSlides = () => {
  return axios.get('http://127.0.0.1:5000/api/get_slides', { withCredentials: true });
};

export const regenerateSlide = (prompt, index) => {
  return axios.post('http://127.0.0.1:5000/api/regenerate_slide', { prompt, index }, { withCredentials: true });
};

export const regenerateOverall = (prompt) => {
  return axios.post('http://127.0.0.1:5000/api/regenerate_overall', { prompt }, { withCredentials: true });
};

export const saveSlides = (slides) => {
  return axios.post('http://127.0.0.1:5000/api/save_slides', { slides }, { withCredentials: true });
};

export const exportSlides = () => {
  return axios.get('http://127.0.0.1:5000/api/export_slides', { withCredentials: true, responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'presentation.pptx');
      document.body.appendChild(link);
      link.click();
    });
};
