import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './LandingPage.css';
import { SlidesContext } from '../../context/SlidesContext';
import Loader from './Loader';

const LandingPage = () => {
  const [presentationDescription, setPresentationDescription] = useState('');
  const [informationalContent, setInformationalContent] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setSlides } = useContext(SlidesContext);

  const handleGenerateSlides = () => {
    console.log('Sending request to generate slides');
    console.log('Presentation Description:', presentationDescription);
    console.log('Informational Content:', informationalContent);
    setLoading(true);
    axios
      .post(
        'http://127.0.0.1:5000/api/generate_slides',
        {
          presentation_description: presentationDescription,
          informational_content: informationalContent,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log('Generated Slides:', response.data);
        setSlides(response.data);
        navigate('/editor');
      })
      .catch((error) => {
        console.error('There was an error generating slides!', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="landing-page">
      <nav className="navbar">
        <div className="logo">SlidesGen.org</div>
        <ul className="nav-links">
          <li>
            <a href="#features">Features</a>
          </li>
          <li>
            <a href="#how-it-works">How It Works</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>

      <header className="hero-section">
        <div className="hero-content">
          <h1>Create Stunning Presentations Effortlessly</h1>
          <p>
            Generate professional slides in seconds with our AI-powered tool. Focus on your ideas, and let us handle the
            rest.
          </p>
          <button
            className="cta-button"
            onClick={() =>
              document.querySelector('.input-section').scrollIntoView({ behavior: 'smooth' })
            }
          >
            Get Started
          </button>
        </div>
        <div className="hero-image">
          {/* Replace with your image */}
          <img src="hero-image.svg" alt="Presentation Illustration" />
        </div>
      </header>

      <main className="main-content">
        <section className="input-section">
          <h2>Generate Your Presentation</h2>
          <div className="form-group">
            <label htmlFor="presentationDescription">Presentation Description</label>
            <textarea
              id="presentationDescription"
              placeholder="Enter a brief description of your presentation topic..."
              value={presentationDescription}
              onChange={(e) => {
                setPresentationDescription(e.target.value);
                console.log('Presentation Description:', e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="informationalContent">Informational Content</label>
            <textarea
              id="informationalContent"
              placeholder="Paste any content you want to include..."
              value={informationalContent}
              onChange={(e) => {
                setInformationalContent(e.target.value);
                console.log('Informational Content:', e.target.value);
              }}
            />
          </div>
          <button className="generate-button" onClick={handleGenerateSlides} disabled={loading}>
            {loading ? <span className="loader"></span> : 'Generate Slides'}
          </button>
        </section>
      </main>

      <footer className="footer">
        <p>&copy; 2024 SlideGen. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
