/*

src/components/SlidePreview/SlidePreview.js

This component is responsible for rendering the SlidePreview component. 
It renders the title, subtitle, and bullets of the slide.

SlidePreview.js handles the state of the editor components and updates the slides state when the editor components are changed.

*/

import React from 'react';
import './SlidePreview.css';

const SlidePreview = ({ titleEditorState, subtitleEditorState, bulletsEditorState }) => {
  const renderContent = (editorState) => editorState.getCurrentContent().getPlainText();

  return (
    <div className="slide-preview">
      <div className="slide-content">
        <h3 className="slide-title">{renderContent(titleEditorState)}</h3>
        <h5 className="slide-subtitle">{renderContent(subtitleEditorState)}</h5>
        <ul className="slide-bullets">
          {renderContent(bulletsEditorState).split('\n').map((bullet, index) => (
            <li key={index} className="slide-bullet">{bullet}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SlidePreview;
