/*

This is the main component of the application. 
It uses the React Router to navigate between the LandingPage and the EditorPage components. 
The SlidesProvider component is used to provide the slides context to the entire application.

*/

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/layout/LandingPage/LandingPage';
import EditorPage from './components/layout/EditorPage/EditorPage';
import { SlidesProvider } from './components/context/SlidesContext';

function App() {
  return (
    <SlidesProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/editor" element={<EditorPage />} />
          </Routes>
        </div>
      </Router>
    </SlidesProvider>
  );
}

export default App;
