/*

src/components/EditorPage/EditorPage.js

This component is the main component for the slide editor. 
It contains the main logic for the slide editor, including the logic for handling the slides, the prompts, and the overall prompt. 
It also contains the logic for handling the export of the slides to PowerPoint.

*/

import React, { useEffect, useContext, useRef } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { SlidesContext } from '../../context/SlidesContext';
import './EditorPage.css';
import SlideContainer from '../../slides/SlideContainer/SlideContainer';
import SlidePreview from '../../slides/SlidePreview/SlidePreview';
import RightSidebar from '../RightSidebar/RightSidebar';
import useEditorPage from '../../../hooks/useEditorPage';
import { getSlides } from '../../../utils/api';

const EditorPage = () => {
  const { slides, setSlides } = useContext(SlidesContext);
  const {
    titleEditorStates,
    setTitleEditorStates,
    subtitleEditorStates,
    setSubtitleEditorStates,
    bulletsEditorStates,
    setBulletsEditorStates,
    slidePrompts,
    setSlidePrompts,
    activeSlideIndex,
    setActiveSlideIndex,
    handleSlidePromptSubmit,
    handleOverallPromptSubmit,
    handleExportSlides,
    setOverallPrompt,
    overallPrompt,
  } = useEditorPage(slides, setSlides);

  const slideContainerRef = useRef(null);
  const sidebarRef = useRef(null);
  const rightSidebarRef = useRef(null);

  useEffect(() => {
    getSlides()
      .then((response) => {
        const fetchedSlides = response.data;
        setSlides(fetchedSlides);
        initializeEditorStates(fetchedSlides);
      })
      .catch((error) =>
        console.error('There was an error fetching the slides!', error)
      );
  }, [setSlides]);

  const initializeEditorStates = (slides) => {
    setTitleEditorStates(
      slides.map((slide) =>
        EditorState.createWithContent(
          ContentState.createFromText(slide.title || '')
        )
      )
    );
    setSubtitleEditorStates(
      slides.map((slide) =>
        EditorState.createWithContent(
          ContentState.createFromText(slide.subtitle || '')
        )
      )
    );
    setBulletsEditorStates(
      slides.map((slide) =>
        EditorState.createWithContent(
          ContentState.createFromText((slide.bullets || []).join('\n'))
        )
      )
    );
    setSlidePrompts(slides.map(() => ''));
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
        const delta = Math.sign(event.deltaY);
        const zoomFactor = delta < 0 ? 1.1 : 0.9;

        if (
          slideContainerRef.current &&
          slideContainerRef.current.contains(event.target)
        ) {
          slideContainerRef.current.style.transform = `scale(${zoomFactor})`;
        } else if (
          sidebarRef.current &&
          sidebarRef.current.contains(event.target)
        ) {
          sidebarRef.current.style.transform = `scale(${zoomFactor})`;
        } else if (
          rightSidebarRef.current &&
          rightSidebarRef.current.contains(event.target)
        ) {
          rightSidebarRef.current.style.transform = `scale(${zoomFactor})`;
        }
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  if (
    !slides ||
    !titleEditorStates.length ||
    !subtitleEditorStates.length ||
    !bulletsEditorStates.length
  ) {
    return <div>Loading...</div>;
  }

  return (
    <div className="slide-editor">
      <div className="top-bar">
        <h1>Slide Editor</h1>
        <button id="export-btn" onClick={handleExportSlides}>
          Export to PowerPoint
        </button>
      </div>
      <div className="slide-editor-container">
        <div className="sidebar" ref={sidebarRef}>
          {slides.length ? (
            slides.map((slide, index) => (
              <div
                key={index}
                className={`slide-thumbnail ${
                  index === activeSlideIndex ? 'active' : ''
                }`}
                onClick={() => setActiveSlideIndex(index)}
              >
                <SlidePreview
                  titleEditorState={titleEditorStates[index]}
                  subtitleEditorState={subtitleEditorStates[index]}
                  bulletsEditorState={bulletsEditorStates[index]}
                />
              </div>
            ))
          ) : (
            <p>No slides available</p>
          )}
        </div>
        <div className="main-slide-editor" ref={slideContainerRef}>
          <SlideContainer
            index={activeSlideIndex}
            titleEditorState={titleEditorStates[activeSlideIndex]}
            titleEditorStates={titleEditorStates}
            setTitleEditorStates={setTitleEditorStates}
            subtitleEditorState={subtitleEditorStates[activeSlideIndex]}
            subtitleEditorStates={subtitleEditorStates}
            setSubtitleEditorStates={setSubtitleEditorStates}
            bulletsEditorState={bulletsEditorStates[activeSlideIndex]}
            bulletsEditorStates={bulletsEditorStates}
            setBulletsEditorStates={setBulletsEditorStates}
            slides={slides}
            setSlides={setSlides}
            slidePrompt={slidePrompts[activeSlideIndex]}
            slidePrompts={slidePrompts}
            setSlidePrompts={setSlidePrompts}
          />
        </div>
        <RightSidebar
          activeSlideIndex={activeSlideIndex}
          slidePrompt={slidePrompts}
          setSlidePrompt={setSlidePrompts}
          handleSlidePromptSubmit={handleSlidePromptSubmit}
          ref={rightSidebarRef}
        />
      </div>
      <div className="overall-prompt-container">
        <textarea
          className="textarea-prompt"
          placeholder="What do you want to change overall?"
          value={overallPrompt}
          onChange={(e) => setOverallPrompt(e.target.value)}
        />
        <button onClick={handleOverallPromptSubmit}>Submit Overall Prompt</button>
      </div>
    </div>
  );
};

export default EditorPage;
