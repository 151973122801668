/*

src/components/SlideComponents/SlideTitleEditor.js

This component is responsible for rendering the SlideTitleEditor component.

*/

import React from 'react';
import { Editor } from 'draft-js';
import './SlideTitleEditor.css';

const SlideTitleEditor = ({ editorState, onChange }) => {
  return (
    <div className="slide-title-editor">
      <h1>
        <Editor editorState={editorState} onChange={onChange} placeholder="Enter slide title" />
      </h1>
    </div>
  );
};

export default SlideTitleEditor;
