/*

src/components/SlideContainer/SlideContainer.js

This component is responsible for rendering the SlideTitleEditor, SlideSubtitleEditor, and SlideBulletsEditor components. 
It also handles the state of the editor components and updates the slides state when the editor components are changed.

*/

import React from 'react';
import SlideTitleEditor from '../SlideComponents/SlideTitleEditor';
import SlideSubtitleEditor from '../SlideComponents/SlideSubtitleEditor';
import SlideBulletsEditor from '../SlideComponents/SlideBulletsEditor';
import './SlideContainer.css';

const SlideContainer = ({
  index,
  titleEditorState,
  titleEditorStates,
  setTitleEditorStates,
  subtitleEditorState,
  subtitleEditorStates,
  setSubtitleEditorStates,
  bulletsEditorState,
  bulletsEditorStates,
  setBulletsEditorStates,
  slides,
  setSlides,
  slidePrompts,
  setSlidePrompts,
}) => {
  const handleTitleChange = (editorState) => {
    const newEditorStates = [...titleEditorStates];
    newEditorStates[index] = editorState;
    setTitleEditorStates(newEditorStates);

    const newSlides = [...slides];
    newSlides[index].title = editorState.getCurrentContent().getPlainText();
    setSlides(newSlides);
  };

  const handleSubtitleChange = (editorState) => {
    const newEditorStates = [...subtitleEditorStates];
    newEditorStates[index] = editorState;
    setSubtitleEditorStates(newEditorStates);

    const newSlides = [...slides];
    newSlides[index].subtitle = editorState.getCurrentContent().getPlainText();
    setSlides(newSlides);
  };

  const handleBulletsChange = (editorState) => {
    const newEditorStates = [...bulletsEditorStates];
    newEditorStates[index] = editorState;
    setBulletsEditorStates(newEditorStates);

    const newSlides = [...slides];
    newSlides[index].bullets = editorState.getCurrentContent().getPlainText().split('\n');
    setSlides(newSlides);
  };

  return (
    <div className="slide-container-outer">
      <div className="slide-container">
        <SlideTitleEditor editorState={titleEditorState} onChange={handleTitleChange} />
        <SlideSubtitleEditor editorState={subtitleEditorState} onChange={handleSubtitleChange} />
        <SlideBulletsEditor editorState={bulletsEditorState} onChange={handleBulletsChange} />
      </div>
    </div>
  );
};

export default SlideContainer;
