/**
 * useEditorPage.js
 * 
 * A custom React hook that manages the state and logic for the Editor Page. 
 * It handles editor state management, slide regeneration, prompts, and exporting slides.
 * 
 * Dependencies:
 * - React (useState)
 * - draft-js (EditorState, ContentState)
 * - API utilities: regenerateSlide, regenerateOverall, saveSlides, exportSlides
 */

import { useState } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { regenerateSlide, regenerateOverall, saveSlides, exportSlides } from '../utils/api';

/**
 * Main hook that provides state and logic for managing slide content and interactions.
 * 
 * @param {Array} slides - Array of slide objects containing title, subtitle, and bullet content.
 * @param {Function} setSlides - Setter function to update the `slides` state.
 * @returns {Object} An object containing editor states, handlers, and state setters.
 */
const useEditorPage = (slides, setSlides) => {
  // Editor states for the title, subtitle, and bullet points of each slide
  const [titleEditorStates, setTitleEditorStates] = useState([]);
  const [subtitleEditorStates, setSubtitleEditorStates] = useState([]);
  const [bulletsEditorStates, setBulletsEditorStates] = useState([]);

  // Stores the input prompts for slide-specific generation
  const [slidePrompts, setSlidePrompts] = useState([]);
  
  // Tracks the currently active slide's index in the UI
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  
  // Stores the overall prompt input for generating a full set of slides
  const [overallPrompt, setOverallPrompt] = useState("");

  /** 
   * Submits a slide-specific prompt to regenerate content for a given slide index.
   * 
   * @param {number} index - The index of the slide to regenerate.
   */
  const handleSlidePromptSubmit = async (index) => {
    try {
      const response = await regenerateSlide(slidePrompts[index], index);
      const updatedSlide = response.data;
      updateSlide(index, updatedSlide);
    } catch (error) {
      console.error("Error processing the slide prompt:", error);
    }
  };

  /** 
   * Submits the overall prompt to generate a complete set of slides.
   */
  const handleOverallPromptSubmit = async () => {
    try {
      const response = await regenerateOverall(overallPrompt);
      const generatedSlides = response.data;
      setSlides(generatedSlides);
      initializeEditorStates(generatedSlides);
    } catch (error) {
      console.error("Error processing the overall prompt:", error);
    }
  };

  /**
   * Saves the current slides and triggers the download of an exported PPTX file.
   */
  const handleExportSlides = async () => {
    try {
      await saveSlides(slides); // Save current slide data to the session

      const response = await exportSlides(); // Request the exported PPTX file
      if (!response.ok) throw new Error("Failed to export slides");

      const blob = await response.blob();
      triggerDownload(blob, 'presentation.pptx'); // Trigger download for the user
    } catch (error) {
      console.error("Error saving or exporting slides:", error);
    }
  };

  /**
   * Triggers a browser download for a given file blob.
   * 
   * @param {Blob} blob - The file blob to download.
   * @param {string} filename - The desired name of the downloaded file.
   */
  const triggerDownload = (blob, filename) => {
    const url = window.URL.createObjectURL(blob); // Create object URL from the blob
    const a = document.createElement('a'); // Create a link element
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // Append the link to the DOM
    a.click(); // Simulate a click to start the download
    a.remove(); // Remove the link element from the DOM
  };

  /**
   * Updates the slide content and corresponding editor states.
   * 
   * @param {number} index - The index of the slide to update.
   * @param {Object} updatedSlide - The new content for the slide.
   */
  const updateSlide = (index, updatedSlide) => {
    const newSlides = [...slides]; // Copy the current slides array
    newSlides[index] = updatedSlide; // Update the slide at the specified index
    setSlides(newSlides); // Update the slides state
    updateEditorStates(index, updatedSlide); // Update editor states for the slide
  };

  /**
   * Updates the editor states for a specific slide's title, subtitle, and bullets.
   * 
   * @param {number} index - The index of the slide being updated.
   * @param {Object} updatedSlide - The new content of the slide.
   */
  const updateEditorStates = (index, updatedSlide) => {
    setTitleEditorStates(updateStateArray(titleEditorStates, index, updatedSlide.title));
    setSubtitleEditorStates(updateStateArray(subtitleEditorStates, index, updatedSlide.subtitle));
    setBulletsEditorStates(updateStateArray(
      bulletsEditorStates, index, (updatedSlide.bullets || []).join('\n')
    ));
  };

  /**
   * Creates a new state array with updated content for a specific index.
   * 
   * @param {Array} states - The current state array (title, subtitle, or bullets).
   * @param {number} index - The index to update in the state array.
   * @param {string} content - The new content for the editor state.
   * @returns {Array} A new state array with the updated content.
   */
  const updateStateArray = (states, index, content) => {
    const newStates = [...states];
    newStates[index] = EditorState.createWithContent(ContentState.createFromText(content || ''));
    return newStates;
  };

  /**
   * Initializes editor states for all slides.
   * 
   * @param {Array} slides - The slides to initialize editor states for.
   */
  const initializeEditorStates = (slides) => {
    setTitleEditorStates(createEditorStates(slides, 'title'));
    setSubtitleEditorStates(createEditorStates(slides, 'subtitle'));
    setBulletsEditorStates(createEditorStates(slides, 'bullets', '\n'));
    setSlidePrompts(slides.map(() => "")); // Initialize slide prompts as empty strings
  };

  /**
   * Creates an array of editor states for a specific content type (title, subtitle, bullets).
   * 
   * @param {Array} slides - The slides to generate editor states for.
   * @param {string} key - The key to extract content from (e.g., 'title', 'subtitle').
   * @param {string} [delimiter=''] - Optional delimiter to join content (for bullets).
   * @returns {Array} An array of editor states.
   */
  const createEditorStates = (slides, key, delimiter = '') =>
    slides.map(slide =>
      EditorState.createWithContent(ContentState.createFromText((slide[key] || []).join(delimiter)))
    );

  /** 
   * Expose all state variables and handler functions to be used by the Editor Page component.
   */
  return {
    titleEditorStates,
    setTitleEditorStates,
    subtitleEditorStates,
    setSubtitleEditorStates,
    bulletsEditorStates,
    setBulletsEditorStates,
    slidePrompts,
    setSlidePrompts,
    activeSlideIndex,
    setActiveSlideIndex,
    handleSlidePromptSubmit,
    handleOverallPromptSubmit,
    handleExportSlides,
    setOverallPrompt,
    overallPrompt,
  };
};

export default useEditorPage;
